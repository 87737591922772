import React from 'react';
import { useInnerWidth } from 'src/hooks/dimensions';
import { flexCenter, heights } from 'src/styles/commonStyles';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import { LangSelect } from './LangSelect';
import { useIsRTL } from '../hooks/useIsRTL';
import { Menu } from '@mui/material';
import { AccessibilityMenu } from './AccessibilityMenu';
import { useA11yTheme } from 'src/styles/useA11yTheme';

const StyledHeader = styled.div<{}>`
  border-top: 6px solid ${(props) => props.theme.colors.primary};
  min-height: ${heights.header}px;
  max-height: ${heights.header}px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  font-size: 30px;
  padding: 0px 10px;
  justify-content: center;
`;

const SpanStyled = styled.div<{}>`
  font-family: Rubik;
  color: ${(props) => props.theme.textColors.primaryTextColor};
`;

const Header: React.FC = () => {
  const dispatch = useAppDispatch();

  useInnerWidth();

  const isRTL = useIsRTL();

  const isMobile = useAppSelector((state) => isMobileSelector(state));
  const { theme } = useA11yTheme();

  return (
    <StyledHeader>
      <div
        style={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          direction: 'rtl',
        }}
      ></div>
      <SpanStyled
        style={{
          textAlign: 'center',
          color: theme.textColors.darkTextcolor,
          fontSize: isMobile ? '22px' : '32px',
        }}
      >
        תהליך הזדהות מול רשום המיסים
      </SpanStyled>
      <div style={{ width: '50px', position: 'absolute', right: 0 }}></div>
    </StyledHeader>
  );
};

export default Header;

import React from 'react';

export const useEffectTrigger = (cb: Function) => {
  const [triggerCount, setTriggerCount] = React.useState(0);

  const trigger = () => {
    setTriggerCount((prev) => prev + 1);
  };

  React.useEffect(() => {
    if (!triggerCount) return;
    cb();
  }, [triggerCount]);

  return {
    trigger,
  };
};

import Routing from './Routing/Routing';
import bgSvg from './images/bg.svg';
import * as React from 'react';
import i18n from './i18n';
import { useAppSelector } from './store/hooks';
import { ThemeProvider } from 'styled-components';
import { useA11yTheme } from './styles/useA11yTheme';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { a11ySelector } from './store/slices/mainSlice';

const imageAtt = {
  backgroundImage: `url(${bgSvg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
};

function App() {
  const language = useAppSelector((state) => state.main.ui.language);

  React.useEffect(() => {
    const dir = i18n.dir(language);
    document.documentElement.dir = dir;
    i18n.changeLanguage(language);
  }, [language]);

  const isLinkHighlightingActive = useAppSelector((state) => a11ySelector(state).isLinkHighlightingActive);
  const { theme, isHightContrast, isBlackAndWhite } = useA11yTheme();

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: theme.colors.primary,
        light: theme.colors.primary,
      },
      mode: isHightContrast ? 'dark' : 'light',
    },
  });

  const bgAtt = isHightContrast
    ? {
        backgroundColor: '#202020',
      }
    : imageAtt;

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider
        theme={{
          ...theme,
          isLinkHighlightingActive,
          isBlackAndWhite,
          isHightContrast,
        }}
      >
        <div
          style={{
            ...bgAtt,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            opacity: '1',
          }}
        />
        <Routing />
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;

export const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
export const mobilePhoneRegex = new RegExp(/^05\d{8}$/);
export const digitRegex = new RegExp('[0-9]');
export const onlyDigitRegex = new RegExp('^[0-9]*$');
export const isoDateRegex = new RegExp(/^\d{4}–\d{2}–\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)Z$/);
export const positiveNumberRegex = /^[0-9]*$/;
export const fleetDateFormat = new RegExp(/^\d{4}[.|-]\d{2}[.|-]\d{2}\s\d{2}:\d{2}$/);
export const digitOrPlusSymbolRegex = /^$|^([+]?\d{1,32})$/;
export const digitOrCharRegex = /[A-Za-z0-9]/;

// 'hh:mm'
export const timeDigit = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const hasValidCoords = <T>(address: T): address is T & { lat: number; lng: number } => {
  return address && 'lat' in address && 'lng' in address && (address as any).lat && (address as any).lng;
};

export enum EnvKeys {
  GoogleMapsApiKey = 'REACT_APP_MAPS_JS_API_KEY',
  NodeEnvironment = 'NODE_ENV',
  GoogleMapsApiKeyNoRestrict = 'REACT_APP_MAPS_JS_API_KEY_NO_RESTRICT',
}

export const isDevEnv = process.env[EnvKeys.NodeEnvironment] === 'development';

export type BaseError = {
  message?: string;
};

export const MAPS_API_KEY = process.env[EnvKeys.GoogleMapsApiKey] || '';
export const MAPS_API_KEY_NO_RESTRICT = process.env[EnvKeys.GoogleMapsApiKeyNoRestrict] || '';

// eslint-disable-next-line no-var
export var CARS_INPUTS_LOCAL_STORAGE_KEY = 'carsInputItems';

export const toFixed = (num: number | undefined, digits = 1): string => {
  try {
    if (!num) {
      return '0';
    }

    if (num % 1 === 0) {
      return String(num);
    }
    return num.toFixed(digits);
  } catch (error) {
    console.log(error);
    return String(num);
  }
};

const getTextWithSeparator = (text: string | undefined | null | number, separator = ','): string => {
  return text ? `${text}${separator}` : '';
};

export const stringifyAddressV2 = (
  rawAddress: { city?: string; street?: string; houseNum?: string } | undefined,
  cityFirst?: boolean
): string => {
  try {
    if (!rawAddress) return '';

    return `${rawAddress.street || ''} ${getTextWithSeparator(rawAddress.houseNum)} ${
      rawAddress.city || ''
    }`.trim();
  } catch (error) {
    console.log(error);
    return 'לא ניתן להציג את הכתובת';
  }
};

export const getErrWithCode = (err: string, code: number, t: (txt: string) => string) => {
  return `${t(err)} ( ${t('code')} ${code} )`;
};

export const isNumericStr = (str: string) => {
  return !isNaN(Number(str));
};

export const colors = {
  primary: '#2196F3',
  primaryLight: '#96d0ff',
  primaryDark: '#156ab0',
  white: '#fff',
  black: 'rgb(0,0,0)',
  blue: '#1976d2',
  midGray: '#C4C4C4',
  mediumLightGray: '#E8EBEF',
  veryLightGray: '#F9F9F9',
  lightGray: '#dddddd',
  lightYellow: '#FFEDB1',
  blueOcean: '#BCEFFF',
  muiRed: '#d32e2e',
} as const;

export const textColors = {
  primaryTextColor: '#494949',
  darkTextcolor: '#333333',
  lightTextColor: '#484644',
  veryLightTextColor: '#BEBEBE',
  yellow: '#FFD42A',
};

export const fontSizes = {
  s: '14px',
  sm: '16px',
  m: '18px',
  ml: '24px',
  lg: '30px',
  xl: '36px',
};

export const fontFamilies = {
  SalsaRegular: 'Salsa-Regular',
  Rubik: 'Rubik',
};

export const deviceSizes = {
  desktopS: 1720,
  laptopM: 1605,
  laptopS: 1440,
  tablet: 768,
  mobileL: 550,
  mobileM: 375,
  mobileS: 320,
};

export const breakPoints = {
  tablet: `(max-width: ${deviceSizes.tablet}px)`,
  mobileL: `(max-width: ${deviceSizes.mobileL}px)`,
  mobileM: `(max-width: ${deviceSizes.mobileM}px)`,
  mobileS: `(max-width: ${deviceSizes.mobileS}px)`,
  laptopS: `(max-width: ${deviceSizes.laptopS}px)`,
  laptopM: `(max-width: ${deviceSizes.laptopM}px)`,
  desktopS: `(max-width: ${deviceSizes.desktopS}px)`,
};

export const blackAndWhiteColors = {
  primary: '#000000', // Black for primary elements
  primaryLight: '#696969',
  primaryDark: '#000000',
  white: '#ffffff',
  black: '#000000',
  blue: '#424242', // Gray equivalent of blue
  midGray: '#757575',
  mediumLightGray: '#BDBDBD',
  veryLightGray: '#E0E0E0',
  lightGray: '#BDBDBD',
  lightYellow: '#9E9E9E', // Gray equivalent for yellow
  blueOcean: '#757575', // Gray equivalent for blueOcean
  muiRed: '#424242', // Gray equivalent for red
} as const;

export const blackAndWhiteTextColors = {
  primaryTextColor: '#212121',
  darkTextcolor: '#000000',
  lightTextColor: '#424242',
  veryLightTextColor: '#757575',
  yellow: '#9E9E9E', // Gray equivalent for yellow text
};

export const highContrastColors = {
  primary: '#48b3ff', // Darker blue for primary
  primaryLight: '#48b3ff',
  primaryDark: '#008cff',
  white: '#000000',
  black: '#ffffff',
  blue: '#2e81ff', // Same as primary for consistency
  midGray: '#ffffff', // Darker gray
  mediumLightGray: '#ffffff', // Slightly darker
  veryLightGray: '#fffbfb', // Adjusted light gray
  lightGray: '#ffffff', // Same as mediumLightGray for better contrast
  lightYellow: '#ffef0c', // Brighter yellow
  blueOcean: '#00aeff', // Lighter, brighter blue
  muiRed: '#ff0000', // Darker red for better visibility
} as const;

export const highContrastTextColors = {
  primaryTextColor: '#ffffff', // Darker for better readability
  darkTextcolor: '#ffffff', // Pure black for maximum contrast
  lightTextColor: '#ffffff', // Darker shade
  veryLightTextColor: '#ffffff', // Adjusted for contrast
  yellow: '#ffe14d', // Brighter yellow
};

import React from 'react';
import { flexCenter } from 'src/styles/commonStyles';
import { BaseCard } from 'src/components/BaseCard';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import { isMobileSelector } from 'src/store/slices/commonSlice';

export const LoadingCard: React.FC<{}> = () => {
  const isMobile = useAppSelector((state) => isMobileSelector(state));

  return (
    <BaseCard style={!isMobile ? { height: '400px' } : {}}>
      <div style={{ ...flexCenter, height: '100%' }}>
        <CircularProgress />
      </div>
    </BaseCard>
  );
};

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import commonSlice from './slices/commonSlice';
import authSlice from './slices/authSlice';
import mainSlice from './slices/mainSlice';
import taxAuthSlice from './slices/taxAuthSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    common: commonSlice,
    main: mainSlice,
    taxAuth: taxAuthSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

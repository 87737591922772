import React from 'react';
import styled from 'styled-components';
import Header from 'src/components/Header';
import InfoFooter from 'src/components/InfoFooter';
import { heights } from 'src/styles/commonStyles';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { taxAuthApi } from './taxAuthApi';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import { InvalidMailLinkCard } from './components/InvalidMailLink';
import { TaxAuthSuccess } from './components/TaxAuthSuccess';
import { LoadingCard } from './components/LoadingCard';
import { authTaxRootSelector, taxAuthSliceActions } from 'src/store/slices/taxAuthSlice';
import { ResultCodes, STATE_LS_KEY } from './taxAuthUtils';
import { useEffectTrigger } from 'src/hooks/useEffectTrigger';

const { innerHeight } = window;

export const CLIENT_TOKEN_KEY = 'clientId';

export const TaxAuthScreen: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const state = useAppSelector((state) => authTaxRootSelector(state));
  const isMobile = useAppSelector((state) => isMobileSelector(state));

  const clientId = localStorage.getItem(CLIENT_TOKEN_KEY) || '';

  const aElementRef = React.useRef<HTMLAnchorElement>(null);

  const linkElementClickTrigger = useEffectTrigger(() => {
    console.log('trigger clicking');
    aElementRef.current?.click();
  });

  const getStageCard = () => {
    if (state.currStage === 1) return <LoadingCard />;
    if (state.currStage === 3) return <TaxAuthSuccess />;
    if (state.currStage === 4) return <InvalidMailLinkCard />;
  };

  React.useEffect(() => {
    const asyncHandler = async () => {
      const uuidFromLocalStorage = localStorage.getItem('uuid') || '';

      const returnedCodeParam = searchParams.get('code') || '';
      console.log('returnedCodeParam', returnedCodeParam);
      const clientIdParam = searchParams.get(CLIENT_TOKEN_KEY);
      const uuidParam = searchParams.get('uuid') || '';

      // ------------------------------------- returning to page after redirect
      if (returnedCodeParam) {
        try {
          const res = await taxAuthApi.generateTokens({
            code: returnedCodeParam,
            uuid: uuidFromLocalStorage,
          });
          // success
          if (res.isSuccess) {
            dispatch(taxAuthSliceActions.setStage(3));
            localStorage.clear();
            return;
          }

          // failure
        } catch (error) {
          console.log(error);
        }
        return;
      }

      // ------------------------------------ new page
      // invalid url
      if (!uuidParam || !clientIdParam) {
        dispatch(taxAuthSliceActions.setStage(4));
        dispatch(taxAuthSliceActions.setValue({ key: 'errCode', value: ResultCodes.MissingUrlParams }));
        return;
      }

      // valid url
      localStorage.setItem('uuid', uuidParam);
      localStorage.setItem(CLIENT_TOKEN_KEY, clientIdParam);

      taxAuthApi
        .init({ uuid: uuidParam })
        .then((res) => {
          if (res.isSuccess) {
            linkElementClickTrigger.trigger();
            return;
          }

          dispatch(taxAuthSliceActions.setStage(4));
        })
        .catch((err) => {
          console.log(err);
          dispatch(taxAuthSliceActions.setStage(4));
        });
    };

    asyncHandler();
  }, []);

  React.useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <Cont>
      <Header />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: isMobile ? 'flex-start' : 'center',
          marginTop: isMobile ? '20px' : '0px',
          height: `calc(${innerHeight}px - ${heights.header}px - ${heights.infoFooter}px)`,
        }}
      >
        {getStageCard()}
      </div>
      <a
        ref={aElementRef}
        style={{ display: 'none' }}
        href={`https://openapi.taxes.gov.il/shaam/tsandbox/longtimetoken/oauth2/authorize?response_type=code&client_id=${clientId}&scope=scope`}
      ></a>
      <InfoFooter />
    </Cont>
  );
};

const Cont = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  height: ${innerHeight}px !important;
  min-height: ${innerHeight}px !important;
  min-width: 300px;
  overflow: hidden;
`;

/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageConfig } from 'src/api/types';
import { Endpoints } from 'src/api/utilis';
import { InputConfig } from 'src/screens/Home/components/Form/types.form';
import { RootState } from '..';
import { a11yReducers, apiReducers, DEFAULT_A11Y, formReducers } from '../reducers';

export type LangKeys = 'he' | 'en' | 'ru' | 'ar';

// * Slice
export interface MainSliceState {
  ui: {
    form: {
      values: {
        firstName: string;
        lastName: string;
        phoneNum: string;
        uniqueId: string;
        email: string;
        acceptedYitPrivacyAgreement: boolean;
        acceptedSitePrivacyAgreement: boolean;
      };
      errors: {
        [key in keyof MainSliceState['ui']['form']['values']]?: string[];
      };
    };
    stage: 'form' | '2fa' | 'success' | 'downloadApp' | 'pageNotFound';
    language: LangKeys;
    a11y: {
      contrast: 'normal' | 'high' | 'blackAndWhite';
      isLinkHighlightingActive: boolean;
      isDrawerOpen: boolean;
    };
  };
  api: {
    [Endpoints.GetPageConfig]: {
      data: PageConfig | null;
      status: 'idle' | 'loading' | 'succeeded' | 'failed';
      errMsg: string;
      errCode: number;
    };
  };
}

const localStorageA11y = localStorage.getItem('a11y');

export const initialState: MainSliceState = {
  ui: {
    form: {
      values: {
        firstName: '',
        lastName: '',
        phoneNum: '',
        uniqueId: '',
        email: '',
        acceptedYitPrivacyAgreement: false,
        acceptedSitePrivacyAgreement: false,
      },
      errors: {},
    },
    stage: 'form',
    language: 'he',
    a11y: localStorageA11y ? JSON.parse(localStorageA11y) : DEFAULT_A11Y,
  },
  api: {
    [Endpoints.GetPageConfig]: {
      data: null,
      status: 'idle',
      errMsg: '',
      errCode: 0,
    },
  },
};

export const mainSlice = createSlice({
  name: 'main',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    ...apiReducers,
    ...formReducers,
    ...a11yReducers,
    setLanguage: (state, action: PayloadAction<LangKeys>) => {
      state.ui.language = action.payload;
    },
    setStage: (state, action: PayloadAction<MainSliceState['ui']['stage']>) => {
      state.ui.stage = action.payload;
    },
  },
});

export const mainSliceActions = mainSlice.actions;
export const mainSliceSelector = (state: RootState): MainSliceState => state.main;
export const formSelector = (state: RootState) => state.main.ui.form;
export const a11ySelector = (state: RootState) => state.main.ui.a11y;

export default mainSlice.reducer;

import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useA11yTheme } from 'src/styles/useA11yTheme';

interface Prop extends AlertProps {
  open: boolean;
  onClose: () => void;
}

const Alert = ({ open, onClose, ...props }: Prop) => {
  const { theme, isBlackAndWhite } = useA11yTheme();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={10000}
      open={open}
      onClose={onClose}
      dir="ltr"
      sx={{ marginBottom: '2rem' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClose}
        sx={{
          backgroundColor: isBlackAndWhite ? theme.colors.black : undefined,
        }}
        {...props}
      />
    </Snackbar>
  );
};

export default Alert;

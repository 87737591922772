/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Severity } from '../../hooks/useAlert';
import { RootState } from '..';
import { ResultCodes, STATE_LS_KEY } from 'src/screens/TaxAuthScreen/taxAuthUtils';

// * Slice
export interface TaxAuthSliceState {
  currStage: number;
  errCode: number;
}

const initialState: TaxAuthSliceState = {
  currStage: 1,
  errCode: ResultCodes.Success,
};

export const taxAuthSlice = createSlice({
  name: 'taxAuth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetSlice: (state) => initialState,
    setStage: (state, action: PayloadAction<TaxAuthSliceState['currStage']>) => {
      state.currStage = action.payload;
    },
    setValue: (
      state,
      action: PayloadAction<{
        key: keyof TaxAuthSliceState;
        value: TaxAuthSliceState[keyof TaxAuthSliceState];
      }>
    ) => {
      (state as Record<string, any>)[action.payload.key] = action.payload.value;
    },
  },
});

// * Exports
// ^ Actions
export const taxAuthSliceActions = taxAuthSlice.actions;
// Other code such as selectors can use the imported `RootState` type
// ^ Selectors
export const authTaxRootSelector = (state: RootState): TaxAuthSliceState => state.taxAuth;

export default taxAuthSlice.reducer;

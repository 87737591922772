import axios from 'axios';

const DEFAULT_CONFIG = {
  validateStatus: function (status: any) {
    return status >= 200 && status < 500; // Resolve only if status code is less than 500
  },
};

export const transitInstance = axios.create({
  ...DEFAULT_CONFIG,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
});

const interfaceServicesApiInstance = axios.create({
  ...DEFAULT_CONFIG,
  headers: {},
});

export default interfaceServicesApiInstance;

import { MOBILE_WIDTH_BREAKPOINT } from 'src/hooks/dimensions';

export const flexCenter = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
} as const;

export const flexCol = {
  display: 'flex',
  flexDirection: 'column',
} as const;

export const cs = {
  flexCenter,
  alc: {
    display: 'flex',
    alignItems: 'center',
  },
  jsc: {
    display: 'flex',
    justifyContent: 'center',
  },
} as const;

const screenHeight = window.screen.height;
const isMobile = window.innerWidth < MOBILE_WIDTH_BREAKPOINT;

const headerHeight = 60;
const footerHeight = isMobile ? 40 : 26;
const stepperHeight = 100;
const bodyHeight = screenHeight - headerHeight - footerHeight - stepperHeight;

export const heights = {
  header: headerHeight,
  infoFooter: footerHeight,
  body: bodyHeight,
  stepper: stepperHeight,
};

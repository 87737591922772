import { PayloadAction } from '@reduxjs/toolkit';
import { MainSliceState, initialState } from './slices/mainSlice';

export const apiReducers = {
  setApiRequestSuccess: (
    state: MainSliceState,
    action: PayloadAction<{
      key: keyof MainSliceState['api'];
      data: any;
    }>
  ) => {
    const { key, data } = action.payload;
    state.api[key] = {
      errMsg: '',
      status: 'succeeded',
      data: data,
      errCode: 0,
    };
  },
  setApiRequestLoading: (
    state: MainSliceState,
    action: PayloadAction<{ key: keyof MainSliceState['api'] }>
  ) => {
    const { key } = action.payload;
    state.api[key] = {
      ...state.api[key],
      status: 'loading',
    };
  },
  setApiRequestFailed: (
    state: MainSliceState,
    action: PayloadAction<{ key: keyof MainSliceState['api']; errMsg: string; errCode: number }>
  ) => {
    const { key, errMsg, errCode } = action.payload;
    state.api[key] = {
      ...initialState.api[key],
      errMsg,
      errCode,
      status: 'failed',
    };
  },
};

export const formReducers = {
  // Update a specific field's value
  updateFieldValue: (
    state: MainSliceState,
    action: PayloadAction<{ field: keyof MainSliceState['ui']['form']['values']; value: string | boolean }>
  ) => {
    const { field, value } = action.payload;
    (state.ui.form.values[field] as any) = value;
  },
  // Reset all form errors to an empty object
  resetFormErrors: (state: MainSliceState) => {
    state.ui.form.errors = {};
  },
  // Reset the form values to their initial state
  resetForm: (state: MainSliceState) => {
    state.ui.form = initialState.ui.form;
  },
  resetFieldErrors: (
    state: MainSliceState,
    action: PayloadAction<keyof MainSliceState['ui']['form']['values']>
  ) => {
    state.ui.form.errors[action.payload] = [];
  },
  addErrorToField: (
    state: MainSliceState,
    action: PayloadAction<{ field: keyof MainSliceState['ui']['form']['values']; error: string }>
  ) => {
    const { field, error } = action.payload;
    state.ui.form.errors[field] = [...(state.ui.form.errors[field] || []), error];
  },
  setFieldErrors: (
    state: MainSliceState,
    action: PayloadAction<{ field: keyof MainSliceState['ui']['form']['values']; errors: string[] }>
  ) => {
    const { field, errors } = action.payload;
    state.ui.form.errors[field] = errors;
  },
  setAllErrors: (
    state: MainSliceState,
    action: PayloadAction<{ errors: MainSliceState['ui']['form']['errors'] }>
  ) => {
    const { errors } = action.payload;
    state.ui.form.errors = errors;
  },
};

export const DEFAULT_A11Y = {
  contrast: 'normal',
  isLinkHighlightingActive: false,
  isDrawerOpen: false,
} as const;

export const a11yReducers = {
  setA11yContrast: (
    state: MainSliceState,
    action: PayloadAction<MainSliceState['ui']['a11y']['contrast']>
  ) => {
    state.ui.a11y.contrast = action.payload;
  },
  setA11yLinkHighlighting: (state: MainSliceState, action: PayloadAction<boolean>) => {
    state.ui.a11y.isLinkHighlightingActive = action.payload;
  },
  resetA11y: (state: MainSliceState) => {
    state.ui.a11y = DEFAULT_A11Y;
  },
  toggleA11yDrawer: (state: MainSliceState, action: PayloadAction<boolean>) => {
    state.ui.a11y.isDrawerOpen = action.payload;
  },
};

import React from 'react';
import { useAppSelector } from 'src/store/hooks';
import { a11ySelector } from 'src/store/slices/mainSlice';
import {
  blackAndWhiteColors,
  blackAndWhiteTextColors,
  highContrastColors,
  highContrastTextColors,
  colors,
  textColors,
} from './defaultTheme';

export const useA11yTheme = () => {
  const contrast = useAppSelector((state) => a11ySelector(state).contrast);

  const theme = React.useMemo(() => {
    if (contrast === 'blackAndWhite') {
      return {
        colors: blackAndWhiteColors,
        textColors: blackAndWhiteTextColors,
      };
    }

    if (contrast === 'high') {
      return {
        colors: highContrastColors,
        textColors: highContrastTextColors,
      };
    }

    return {
      colors,
      textColors,
    };
  }, [contrast]);

  return {
    theme,
    isHightContrast: contrast === 'high',
    isBlackAndWhite: contrast === 'blackAndWhite',
  };
};

import { InterfaceServicesApiResponse } from 'src/api/types';

export const taxAuthApi = {
  init: ({ uuid }: { uuid: string }): Promise<InterfaceServicesApiResponse<any>> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          isSuccess: true,
          data: {
            /* some mock initialization data */
          },
          code: 200,
          message: 'Initialization successful',
        });
      }, 500);
    });
  },
  generateTokens: ({
    code,
    uuid,
  }: {
    code: string;
    uuid: string;
  }): Promise<InterfaceServicesApiResponse<any>> => {
    console.log('generateTokens', { code, uuid });
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          isSuccess: true,
          data: {
            /* some mock token data */
          },
          code: 200,
          message: 'Token generation successful',
        });
      }, 500);
    });
  },
};

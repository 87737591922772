import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface AuthSliceState {
  params: {
    interfaceGuid: string;
    accountCode: string;
  };
}

const initialState: AuthSliceState = {
  params: {
    interfaceGuid: '',
    accountCode: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuthParams: (state, action: PayloadAction<AuthSliceState['params']>) => {
      state.params.accountCode = action.payload.accountCode;
      state.params.interfaceGuid = action.payload.interfaceGuid;
    },
    restartAuthSlice: () => {
      return initialState;
    },
  },
});

export const authSliceActions = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const authRootSelector = (state: RootState) => state.auth;

export default authSlice.reducer;

import { CircularProgress } from '@mui/material';
import { MOBILE_WIDTH_BREAKPOINT } from 'src/hooks/dimensions';
import styled, { css } from 'styled-components';
import * as React from 'react';
import { flexCenter } from 'src/styles/commonStyles';

export const BaseCard = styled.div<{ isLoading?: boolean }>`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);
  max-width: 500px;
  padding: ${screen.width < MOBILE_WIDTH_BREAKPOINT ? '20px' : '40px'};
  height: ${screen.width < MOBILE_WIDTH_BREAKPOINT ? `calc(100% - 40px)` : ''};
  justify-content: ${screen.width < MOBILE_WIDTH_BREAKPOINT ? 'space-between' : ''};
  ${({ isLoading = false }) =>
    isLoading
      ? css`
          pointer-events: none;
        `
      : ''}
`;

export const Card: React.FC<{ children: React.ReactNode; isLoading?: boolean }> = ({
  children,
  isLoading = false,
}) => {
  return (
    <BaseCard isLoading={isLoading} style={{ height: `calc(100% - 40px)` }}>
      {isLoading && (
        <div
          style={{
            ...flexCenter,
            position: 'absolute',
            background: '#00000018',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            overflow: 'hidden',
            borderRadius: '16px',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {children}
    </BaseCard>
  );
};

export const CardTitle = styled.p`
  text-align: center;
  font-size: ${screen.width < MOBILE_WIDTH_BREAKPOINT ? '24px' : '32px'};
  font-weight: 700;
  padding-bottom: 16px;
  color: ${(props) => props.theme.textColors.primaryTextColor};
`;

export const CardSubtitle = styled.p<{ withMarginBottom?: boolean }>`
  text-align: center;
  font-size: ${screen.width < MOBILE_WIDTH_BREAKPOINT ? '18px' : '20px'};
  font-weight: 400;
  margin-bottom: ${({ withMarginBottom = true }) => (withMarginBottom ? '24px' : '0')};
  color: ${(props) => props.theme.textColors.primaryTextColor};
`;

import React from 'react';
import { BaseCard, CardTitle } from 'src/components/BaseCard';
import { SuccessImg } from './SuccessImg';

export const TaxAuthSuccess: React.FC<{}> = () => {
  return (
    <BaseCard style={{}}>
      <div style={{}}>
        <CardTitle style={{ paddingBottom: 0 }}>זהו סיימנו!</CardTitle>
        <CardTitle>תהליך הושלם בהצלחה</CardTitle>
      </div>
      <SuccessImg />
      <div></div>
    </BaseCard>
  );
};

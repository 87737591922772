import interfaceServicesApiInstance from '.';
import { InterfaceServicesApiResponse } from './types';

const DEV_LOCAL_URL = 'https://localhost:7261/PassSignUpService/PassSignUpService.svc/web';
const DEV_CONTAINER_URL =
  'https://interfaceserviceapi-dev.y-it.co.il/PassSignUpService/PassSignUpService.svc/web';
const PROD_URL = 'https://interfaceserviceapi-prod.y-it.co.il/PassSignUpService/PassSignUpService.svc/web';

export enum Endpoints {
  GetPageConfig = 'GetPageConfig',
  SendTwoFactorSms = 'SendTwoFactorSMS',
  SavePassengerData = 'SavePassengerData',
}

const URL = process.env.NODE_ENV === 'development' ? DEV_LOCAL_URL : PROD_URL;

export const createUrl = (endpoint: Endpoints, params?: any) => {
  let url = `${URL}/${endpoint}`;
  if (params) {
    url = `${url}?${convertParamsToXFormUrlEncoded(params)}`;
  }
  return url;
};

export const request = async <T>(url: string, body?: unknown): Promise<InterfaceServicesApiResponse<T>> => {
  try {
    const res = await interfaceServicesApiInstance.post(url, body);

    if (!('isSuccess' in res.data)) {
      return {
        isSuccess: false,
        code: 99,
        data: null,
        message: `Invalid response data structure \n\n  ${JSON.stringify(res.data)}`,
      };
    }

    return res.data;
  } catch (error: any) {
    // if is 401, return appropriate msg
    if (error.response?.status === 401) {
      return { isSuccess: false, code: 401, data: null, message: error.response.data };
    }

    return { isSuccess: false, code: 99, data: null, message: error.message };
  }
};

const convertParamsToXFormUrlEncoded = <T extends Record<string, any>>(rawParams: T) => {
  const params = new URLSearchParams();

  // Convert parameters to x-www-form-urlencoded
  for (const key in rawParams) {
    if (Object.prototype.hasOwnProperty.call(rawParams, key)) {
      params.append(key, rawParams[key]);
    }
  }

  return params;
};
